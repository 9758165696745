import React from "react";
import { Link } from "gatsby";
import Macbook from "../../../static/Macbook_mockup.png";

import PraticheArt from "../../../static/pratiche_art.png";
const PraticheAutoCTA_2 = () => {
  return (
    <div className="relative flex flex-col-reverse px-4 py-16 mx-auto lg:block lg:flex-col lg:py-32 xl:py-48 md:px-8 sm:max-w-xl md:max-w-full">
      <div className="z-0 flex justify-center h-full -mx-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-0 lg:left-0 lg:items-center">
        <img
          src={Macbook}
          className="object-cover object-right w-full h-auto lg:w-auto lg:h-full xl:w-auto"
          alt=""
        />
      </div>
      <div className="relative flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl">
        <div className="mb-16 lg:pr-5 lg:max-w-lg lg:mb-0">
          <div className="max-w-xl mb-6">
            <div className="">
              <p className="inline-block relative px-8 py-2 mb-4 text-md font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                <span class="animate-ping absolute block top-[-4px] right-0.5 h-2 w-2 rounded-full ring-4 ring-blue-accent-400 "></span>
                Novità
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
              Semplificiamo le tue
              <br className="hidden md:block" />{" "}
              <span className=" text-blue-accent-400">pratiche auto</span>
            </h2>
            <p className="text-xl text-gray-700 md:text-xl font-normal">
              Passaggio di proprietà, immatricolazione, collaudi, revisioni e
              molto altro: il nostro impegno è semplificare le tue pratiche
              auto.
            </p>
          </div>
          <a
            href="/pratiche-auto"
            aria-label=""
            className="text-xl inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-accent-400 hover:bg-blue-accent-700 focus:shadow-outline focus:outline-none"
          >
            Scopri di più
          </a>
        </div>
      </div>
    </div>
  );
};

export default PraticheAutoCTA_2;
