import { faSailboat, faCar, faFile, faTag, faBellConcierge } from '@fortawesome/free-solid-svg-icons';


export const featureList = [
  {
    name: 'Corsi patente',
    description: 'Corsi teorici e guide specializzate per patenti AM-A1-A2-A-B-C-D-E per auto, moto e non solo',
    icon: faCar,
    link: "/corsi-patente"
  },
  {
    name: 'Patente nautica',
    description: 'Lezioni teoriche ed escursioni in mare per la condotta e il comando di natanti da diporto',
    icon: faSailboat,
    link: "/patenti-nautiche"
  },
  {
    name: 'Pratiche patenti',
    description: 'Tutte le pratiche tra cui rinnovo, duplicato, riclassificazione e conversione della patente',
    icon: faFile,
    link: "/pratiche-patenti"
  },
  {
    name: 'Servizi aggiuntivi',
    description: "Certificato medico, porto d'armi e detenzione arma da fuoco",
    icon: faBellConcierge,
    link: "/servizi-aggiuntivi"
  },
];