import { faSailboat, faCar, faFile, faTag, faSpinner, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { DoubleFeature } from '../../components/DoubleFeature';


export const doubleFeatures =  {
  brand: "Novità",
  list: [
    {
      name: 'Patente nautica',
      description: "L'autoscuola Faro organizza corsi per il conseguimento della patente nautica entro le 12 miglia dalla costa" ,
      description2: "Dai un'occhiata alla nostra pagina patenti nautiche!",
      icon: faSailboat,
      animation: "fa-flip",
      link: "/patenti-nautiche/patente-nautica-12-miglia"
    },
    {
      name: 'Rinnovo patente 2.0',
      description: "Affidati al nostro rinnovo patente 2.0!" ,
      description2: "L'autoscuola Faro fornisce un servizio completo per rinnovare la tua patente",
      icon: faArrowsRotate,
      animation: "fa-spin",
      link: "/pratiche-patenti/rinnovo-patente"
    }
  ]
}
