import React from "react";
import MotoLaw from "../../../static/moto-senza-esame2.jpg";
import { Link } from "gatsby";

const MotorbikeSection = () => {
  return (
    <div className="bg-gray-900 relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col">
      <div className=" w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-extrabold tracking-tight text-white sm:text-5xl sm:leading-none">
            Pronto ad andare in sella?{" "}
            <br className="lg:hidden md:block sm:block" />
            <span className=" text-blue-accent-200">
              {" "}
              Patente Moto Senza Esame
            </span>
          </h2>
          <p className="text-xl text-white">
            Salta esami lunghi e complicati! Autoscuola Faro offre un pacchetto
            completto per ottenere la patente moto senza esame <br />
            Realizza il tuo sogno e solca la strada!
          </p>
          <div className="my-10 text-center md:my-16 lg:my-20">
            <a
              href="/patente-moto-senza-esame"
              aria-label=""
              className="sm:w-1/2 w-full font-extrabold text-lg inline-flex items-center 
              justify-center h-12 px-6 tracking-wide text-white 
              transition duration-200 rounded shadow-md 
              bg-blue-accent-400 hover:bg-blue-accent-700 focus:shadow-outline focus:outline-none"
            >
              Scopri di più
            </a>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-2 text-sm text-gray-600 md:mb-2">
              Seguici sui social
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <a
                  target="_blank"
                  href="https://www.instagram.com/autoscuolafaro/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                    <circle cx="15" cy="15" r="4" />
                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                  </svg>
                </a>
              </div>
              <div className="flex items-center">
                <a
                  target="_blank"
                  href="https://www.facebook.com/autoscuola.faro"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                    <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={MotoLaw}
          alt=""
        />
      </div>
    </div>
  );
};

export default MotorbikeSection;
