import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";

export const FeatureHeader = (props) => {
  return (
    <section
      id={props.id}
      className="relative bg-gradient-to-b from-blue-accent-700 to-blue-accent-400 text-gray-200 body-font"
    >
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:pb-36">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-12 font-sans text-5xl font-extrabold leading-none tracking-tight text-white sm:text-6xl md:mx-auto">
            <span className="relative inline-block"></span>{" "}
            {props.features.title}
          </h2>
        </div>
        <div className="grid max-w-screen-lg gap-12 row-gap-24 mx-auto md:grid-cols-2">
          {props.featureList.map((feature) => (
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mr-4">
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-md bg-teal-accent-400">
                  <FontAwesomeIcon
                    icon={feature.icon}
                    className="fa solid fa-car h-6 w-6 text-teal-900"
                  />
                </div>
              </div>
              <div>
                <h3 className="mb-3 text-3xl font-extrabold font-sans leading-8">
                  {feature.name}
                </h3>
                <p className="mb-2 text-xl text-white">{feature.description}</p>
                <Link
                  href={feature.link}
                  className="text-xl font-roboto font-bold tracking-tight cursor-pointer text-teal-accent-200 hover:text-teal-accent-400  inline-flex items-center"
                >
                  Scopri di pi&ugrave;
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
