import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faSailboat } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/layout/Layout";
import SingleSection from "../components/SingleSection";
import Team from "../components/Team";
import HeroLanding from "../components/home-page/HeroLanding";
import Feature from "../components/Features";
import hero from "../data/home-page/hero.json";
import heroHeader from "../data/home-page/hero-header.json";

import team from "../data/home-page/team.json";
import rinnovo from "../data/home-page/rinnovo.json";
import patenteNautica from "../data/home-page/patente-nautica-section.json";
import features from "../data/home-page/features.json";
import { featureList } from "../data/home-page/featureList";

import "../css/animation.css";
import { HeroPromo } from "../components/HeroPromo";
import { HeroHeader } from "../components/HeroHeader";
import { FeatureHeader } from "../components/FeatureHeader";
import { DoubleFeature } from "../components/DoubleFeature";
import { doubleFeatures } from "../data/home-page/doubleFeatures";
import SEO from "../components/layout/SEO";
import ServiziHeader from "../components/home-page/ServiziHeader";
import ContactSection from "../components/home-page/ContactSection";
import PraticheAutoCTA from "../components/home-page/PraticheAutoCTA";
import PraticheAutoCTA_2 from "../components/home-page/PraticheAutoCTA_2";
import MotorbikeSection from "../components/home-page/MotorbikeSection";

const Index = () => (
  <Layout>
    {/* <HeroPromo
      title={hero.title}
      firstLine={hero.firstLine}
      secondLine={hero.secondLine}
      firstLink={hero.firstLink}
      firstLinkTitle={hero.firstLinkTitle}
      secondLink={hero.secondLink}
      secondLinkTitle={hero.secondLinkTitle}
      imageSrc={boat}
    /> */}
    <SEO title="Autoscuola Faro a Pisa: Patenti e Rinnovi Veloci"
      description={"Svolgiamo pratiche automobilistiche e teniamo corsi per patenti auto, moto, scooter, nautica e non solo. Rinnova la patente qui!"}
      keywords={[
        "autoscuola pisa",
        "autoscuola faro pisa",
        "patente pisa",
        "rinnovo patente pisa",
        "scuola guida pisa",
        "patente nautica pisa",
        "patente moto pisa",
        "pratiche automobilistiche pisa",
        "ufficio pratiche auto pisa",
        "autoscuola faro",
        "agenzia pratiche automobilistiche"
      ]} />

    <HeroLanding />
    {/* <Feature id="services" features={features} featureList={featureList} /> */}

    {/* <PraticheAutoCTA /> */}
    <PraticheAutoCTA_2 />
    <FeatureHeader
      id="services"
      features={features}
      featureList={featureList}
    />
    <ServiziHeader />
    {/* <DoubleFeature doubleFeatures={doubleFeatures} /> */}
    <MotorbikeSection />
    <Team title={team.title} team={team.team} />
    <ContactSection />
  </Layout>
);

export default Index;
