import React from "react";
import Driving from "../../../static/driving.jpg";
import { Link } from "gatsby";
import Guy from "../../../static/guy_driving.jpg";
import Boat from "../../../static/boat_5.jpg";

const ServiziHeader = () => {
  return (
    <div className="my-24 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center lg:order-first md:order-last order-last">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
              Siamo la tua autoscuola di fiducia:
              <br className="block md:block sm:block" />
              <span className=" text-blue-accent-400">
                sulla strada e in mare.
              </span>
            </h2>
            <p className="text-xl text-gray-700">
              Esperienza di guida completa: rinnova la tua patente con facilità
              grazie al nostro servizio Rinnovo patente 2.0 e scopri la libertà
              in mare con la patente nautica entro le 12 miglia.
              <br />
              Autoscuola Faro è sinonimo di innovazione, sicurezza e
              convenienza.
            </p>
          </div>
          <div className="grid gap-8 row-gap-8 sm:grid-cols-2">
            <div>
              <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                <svg
                  className="w-10 h-10"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="#2979ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"
                  />
                </svg>
              </div>
              <h6 className="text-2xl mb-2 font-bold leading-5">
                Rinnovo patente 2.0
              </h6>
              <p className="text-xl text-gray-700">
                Ottieni la tua nuova patente con facilità grazie al nostro
                servizio 2.0.
              </p>
              <Link
                href="/pratiche-patenti/rinnovo-patente"
                className={`mt-3 text-blue-accent-400 inline-flex items-center text-xl`}
              >
                Scopri di più
                <svg
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
            <div>
              <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                <svg
                  className="w-10 h-10"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="#2979ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M192 32c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32V64h48c26.5 0 48 21.5 48 48V240l44.4 14.8c23.1 7.7 29.5 37.5 11.5 53.9l-101 92.6c-16.2 9.4-34.7 15.1-50.9 15.1c-19.6 0-40.8-7.7-59.2-20.3c-22.1-15.5-51.6-15.5-73.7 0c-17.1 11.8-38 20.3-59.2 20.3c-16.2 0-34.7-5.7-50.9-15.1l-101-92.6c-18-16.5-11.6-46.2 11.5-53.9L96 240V112c0-26.5 21.5-48 48-48h48V32zM160 218.7l107.8-35.9c13.1-4.4 27.3-4.4 40.5 0L416 218.7V128H160v90.7zM306.5 421.9C329 437.4 356.5 448 384 448c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 501.7 417 512 384 512c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 437.2 165.1 448 192 448c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z"
                  />
                </svg>
              </div>
              <h6 className="text-2xl mb-2 font-bold leading-5">
                {" "}
                Patente Nautica{" "}
              </h6>
              <p className="text-xl text-gray-700">
                Esplora nuovi orizzonti con la patente nautica entro le 12
                miglia. La libertà in mare è a portata di mano.{" "}
              </p>
              <Link
                href="/patenti-nautiche/patente-nautica-12-miglia"
                className={`mt-3 text-blue-accent-400 inline-flex items-center text-xl`}
              >
                Scopri di più
                <svg
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-72 w-28 sm:w-72 xl:w-72"
              src={Driving}
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src={Guy}
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover h-40 rounded shadow-lg sm:h-64 xl:h-72 w-40 sm:w-72 xl:w-72"
              src={Boat}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiziHeader;
