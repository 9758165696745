import React from "react";
import Macbook from "../../../static/driving-school-faro.jpg";

const HeroLanding = () => {
  return (
    <div className="relative">
      <img
        src={Macbook}
        className="absolute inset-0 object-cover object-right w-full h-full"
        alt="Autoscuola Faro a Pisa" 
      />
      <div className="relative bg-gray-800 bg-opacity-75">
        <div className="md:px-24 lg:px-12 lg:py-48 pb-12 px-4 py-8 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="flex flex-col items-center justify-between xl:flex-row w-full">
            <div className="sm:text-start text-center w-full mb-12 xl:mb-0 xl:pr-16 xl:w-3/4">
              <h1
                className="w-full mb-6 font-sans  font-extrabold tracking-tight text-white 
              sm:text-8xl text-5xl sm:leading-none"
              >
                Autoscuola Faro: <br />
                <span className=" text-white sm:text-7xl text-5xl">
                  {" "}
                  La tua autoscuola
                </span>
                <span className=" text-teal-accent-400 sm:text-7xl text-5xl"> a Pisa</span>
              </h1>
              <h2 className="sm:mt-12 mt-4 text-lg text-white md:text-2xl">
                I nostri istruttori professionisti ti guideranno con successo
                verso la patente auto, moto, nautica e non solo
              </h2>
              <div
                className="flex sm:flex-row flex-col sm:items-center items-center 
              mt-8 space-y-4 sm:space-x-2 sm:space-y-0 sm:w-1/2 w-full"
              >
                <a
                  href="/corsi-patente"
                  aria-label=""
                  className="w-full font-extrabold text-lg inline-flex items-center justify-center h-12 px-6 tracking-wide text-gray-900 transition duration-200 rounded shadow-md bg-teal-accent-400 hover:bg-teal-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Corsi patente
                </a>
                <a
                  href="/contacts"
                  aria-label=""
                  className="w-full font-extrabold text-lg inline-flex items-center justify-center h-12 px-6 tracking-wide text-white bg-blue-accent-400 hover:bg-blue-accent-700 rounded shadow-md"
                >
                  Contattaci
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroLanding;
