/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "gatsby"




const Feature = (props) => {
  return (
    <section id={props.id} className="bg-blue-900 text-gray-200 body-font">
      <div className="container bg-blue-900 mx-auto py-12 px-4 md:px-8 lg:px-8 xl:px-42">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-5xl text-white font-semibold tracking-wide">
              {props.features.title}
            </h2>
            <p className="mt-2 sm:text-2xl text-lg leading-8 font-extrabold tracking-tight text-white">
              {props.features.firstLine}
            </p>
            <p className="text-lg mt-4 max-w-2xl text-md text-white lg:mx-auto">
              {props.features.secondLine}
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-14 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-16">
              {props.featureList.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <FontAwesomeIcon
                        icon={feature.icon}
                        className="fa solid fa-car h-6 w-6 text-white"
                      />
                    </div>
                    <p className="ml-16 text-2xl leading-6 font-medium text-white">
                      {feature.name}
                    </p>
                    
                  </dt>
                  <dd className="mt-2 ml-16 text-lg text-white">{feature.description}</dd>
                  <Link href={feature.link} className="cursor-pointer ml-16 mt-3 text-blue-400 inline-flex items-center">
                    Scopri di pi&ugrave;
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </Link>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
