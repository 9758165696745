import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../css/clip.css";
import { faFacebook, faBolt } from "@fortawesome/free-solid-svg-icons";

const team = [
  {
    id: "team1",
    name: "Fabio Acri",
    img: "/team/Fabio2.jpg",
    role: "Gestore amministrativo dell'azienda",
    description: "Laureato in scienze politiche all'università di Pisa",
    description2:
      "Specializzato in lezioni di guida pratica sui mezzi a quattro ruote.",
    facebook: "https://www.facebook.com/fabio.acri",
    instagram: "https://www.instagram.com/faber_1982/",
  },
  {
    id: "team2",
    name: "Salvatore Simonelli",
    img: "/team/Salvatore.jpg",
    role: "Responsabile didattico della scuola",
    description:
      "Diplomato al Liceo Classico Galileo Galilei di Pisa. Possiede le patenti A, B, BE, C, CE, D, DE E Nautica & 12 Miglia",
    description2:
      "Svolge il ruolo di insegnante e istruttore di guida specializzato in auto, moto e natanti",
    facebook: "https://www.facebook.com/DjSalvy",
    instagram: "https://www.instagram.com/salvy_simonelli/",
  },
  {
    id: "team3",
    name: "Andrea del Pecchia",
    img: "/team/Andrea2.jpg",
    role: "Guida pratica e gestione del parco veicolare",
    description: "Diplomato a ragioneria, svolge il ruolo di istruttore",
    description2:
      "Si occupa di ogni aspetto amministrativo e organizzativo all'interno dell'autoscuola",
    facebook: "https://www.facebook.com/andrea.delpecchia",
    instagram: "https://www.instagram.com/ilpek_95/",
  },
];

const Team = (props) => {
  return (
    <section className="text-gray-400 bg-gradient-to-b from-blue-accent-700 to-blue-accent-400 body-font">
      <div className=" px-2 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="font-sans text-6xl font-extrabold tracking-tight title-font my-8 text-white">
            {props.title}
          </h2>
          <h2 className="text-2xl font-medium title-font mb-4 text-white">
            <Link
              href="/chi-siamo"
              className="font-roboto text-2xl font-bold tracking-tight cursor-pointer text-teal-accent-200 hover:text-teal-accent-400 inline-flex items-center"
            >
              Scopri di pi&ugrave;
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </Link>
          </h2>
        </div>
        <div className="justify-center flex flex-wrap flex-col sm:flex-row -m-4">
          {team.map((team) => (
            <div id={team.id} className="p-4 lg:w-1/3 md:w-1/2">
              <div className="h-full flex flex-col items-center text-center">
                <img
                  alt={team.img}
                  className="flex-shrink-0 rounded-full w-72 h-72 object-cover object-top mb-8"
                  src={team.img}
                />
                <div className="w-full">
                  <h2 className="title-font font-roboto font-semibold tracking-normal text-3xl text-white">
                    {team.name}
                  </h2>
                  <h3 className="text-white font-roboto font-semibold tracking-normal text-xl mb-3">
                    {team.role}
                  </h3>
                  <span className="inline-flex">
                    <div className="flex items-center mt-1 space-x-3">
                      <a
                        href={team.facebook}
                        className="text-gray-100 transition-colors duration-100 hover:text-gray-400"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-5"
                        >
                          <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                        </svg>
                      </a>
                      <a
                        href={team.instagram}
                        className="text-gray-100 transition-colors duration-100 hover:text-gray-400"
                      >
                        <svg
                          viewBox="0 0 30 30"
                          fill="currentColor"
                          className="h-6"
                        >
                          <circle cx="15" cy="15" r="4" />
                          <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                        </svg>
                      </a>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
